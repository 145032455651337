@import '~common/ui_imports';

$img-width: 22rem;

.Synthesized {
  @include media-breakpoint-up(md) {
    .ErrorPageWrapper {
      display: flex;
    }

    .ErrorPage {
      flex: 1;
    }
  }

  @include media-breakpoint-down(md) {
    .ErrorPage {
      min-height: calc(
        100vh - var(--navigation-mobile-header-height) - var(
            --navigation-mobile-footer-height
          )
      );
    }
  }
}

.ErrorPage {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__image-container {
    @include media-breakpoint-up(sm) {
      padding: 5rem 1rem 0;

      &__img-404 {
        padding-top: 2.5rem;
      }
    }

    padding: 2rem 1rem 0;
    max-width: $img-width;

    img {
      width: 100%;
    }
  }

  &__text-container {
    margin: 0 auto;
    padding: 2.5rem 1rem 0;
    max-width: 30rem;

    h1 {
      @include font-type-50--bold;
      @include media-breakpoint-up(sm) {
        @include font-type-70--bold;
      }
      text-align: center;
    }

    h2 {
      @include font-type-20;
      @include media-breakpoint-up(sm) {
        @include font-type-40;
      }
      color: var(--ux-gray-800);
      text-align: center;
    }
  }
}
