@use 'sass:math';

@import 'ux_theme';
@import '~@user-interviews/ui-design-system/scss/theme';

// Bootstrap overrides

$body-bg: $ux-white;
$body-color: $ux-gray-900;
$font-family-base: $body-fonts;
$font-size-base: 0.875rem;
$font-weight-light: 200;
$font-weight-normal: 300;
$font-weight-bold: 700;
$font-weight-bolder: $font-weight-bold; // TODO: remove when RS-8402 is merged
$headings-font-family: $header-fonts;
$headings-font-weight: 400;
$headings-font-weight-bold: 700;
$input-border-color: #ced4da; // bootstrap default;
$input-box-shadow: $input-box-shadow;
$input-disabled-bg: #e9ecef; // bootstrap default;
$input-focus-border-color: $input-focus-border-color;
$link-color: $ux-blue;
$navbar-light-active-color: $ux-researcher;
$navbar-light-color: $body-color;
$navbar-light-hover-color: $navbar-light-color;

// Dimensions: nav items
$nav-tab-border-width: 0.125rem;

// Dimensions: cards

$card-xs-spacing: 1rem;
$card-sm-spacing: 1.5rem;
$card-width: 56rem;
$card-width-xs: 32rem;
$card-width-sm: 40rem;

// Dimensions: forms

$form-max-width: 50rem;
$form-max-width-md: 36rem;
$form-max-width-sm: 26rem;

// Dimensions: search form

$search-form-height: 2.375rem;

// Dimensions: modals
$modal-width-default: 32rem;
$modal-width-wide: 46.5rem;
$close-font-size: 1.5rem;

// Dimensions: sections

$ui-page-spacing: 4rem;
$ui-page-spacing-mobile: math.div($ui-page-spacing, 1.5);
$ui-page-section-navigation-height: 4rem;

// Dimensions: bars

$ui-navbar-height: 3rem;
$ui-header-height: 4.5rem;

// We should probably kill this, but leaving for now in case we want to adjust again:
$ui-navbar-height-mobile: $ui-navbar-height;

$ui-ctabar-height: $ui-navbar-height;
$ui-ctabar-height-mobile: $ui-navbar-height-mobile;

$ui-ctabar-box-shadow: 0 -1px 1px rgba(0, 0, 0, 12%);

// Dimensions: breakpoints

$ui-fixed-position-cta-max-threshold: 600px;

// Dimensions: drawers
$ui-drawer-padding-top: 2.125rem;
$ui-drawer-padding-sides: 1.125rem;
$ui-drawer-padding: $ui-drawer-padding-top $ui-drawer-padding-sides;
$ui-drawer-width: 26.25rem;
$ui-drawer-width-mobile: 100%;

// Dimensions: sidebars
$ui-sidebar-width: 24rem;

$ui-box-shadow-right-anchor: -4px 4px 8px rgba(0, 0, 0, 10%);
$ui-box-shadow-left-anchor: 4px 4px 8px rgba(0, 0, 0, 10%);
$ui-box-shadow-top-anchor: 0 4px 8px rgba(0, 0, 0, 10%);
$ui-box-shadow-bottom-anchor: 0 -4px 8px rgba(0, 0, 0, 10%);

// Colors -- note, look to update `colors.js` when changing these

$theme-colors: (
  'primary': $ux-researcher,
  'success': $ux-success,
  'danger': $ux-error,
  'warning': $ux-warning,
  'info': $ux-blue-500,
  'participant': $ux-participant,
  'light': $ux-gray-light,
  'dark': $ux-gray-800,
  'destroy': $ux-error,
);

$ux-placeholder: lighten($body-color, 30%);
$ux-select-boxshadow: 0 0 0 0.1rem $ux-researcher;
$ux-draggable-boxshadow: 0 0.75rem 1rem rgba(0, 0, 0, 15%);

// Timing functions

$ease-in-circ: cubic-bezier(0.6, 0.04, 0.98, 0.335);
$ease-out-circ: cubic-bezier(0.075, 0.82, 0.165, 1);

// Durations

$duration-brief: 80ms;
$duration-short: 160ms;
$duration-long: 240ms;

// Ignore some things for percy snapshots- all the content of these are
// dynamic, like dates that are randomly generated by the staging seeds.
// This helps our Percy snapshots be more consistent.
// https://docs.percy.io/docs/percy-specific-css#percy-css-media-query
@media only percy {
  input[name='inquiryData.email'],
  .ApplySuccessCard__detail__date,
  .BillingInfoCard__column,
  .CopyToClipboard > span,
  .DataConsentNotice__content__preview__body,
  .MessageList__message__timestamp,
  .ParticipantProfile__participant-details__attribute-value,
  .ProjectCustomEmailSets table tr td:nth-child(2),
  .ProjectListItem__status-date,
  .ProjectListing__details > .ProjectListing__detail:last-of-type,
  .TeamMemberInviterForm__section__invite-via-link,
  .UnmoderatedTask__deadline,
  .participant-dashboard__project__info__status__string,
  .schedule-project-participant-page__time-slots > fieldset > legend {
    visibility: hidden;
  }
}
