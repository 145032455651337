@import '~common/ui_imports';

.sidebar {
  --sidebar-open-width: 17.5rem;

  background: var(--navigation-background-color);
  color: var(--navigation-text-color);
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  height: 100vh;
  padding: 1rem 0;
  position: sticky;
  top: 0;
  transform: translate3d(0, 0, 0);
  transition: 200ms ease-out;
  user-select: none;
  width: var(--page-initial-sidebar-width);
  z-index: var(--z-index-ui-navbar);

  &.isOpen {
    width: var(--sidebar-open-width);
  }
}

.logoWrapper {
  margin-bottom: 0.75rem;
  overflow: hidden;
  padding-left: 0.875rem;
  width: 100%;

  .logo {
    height: 1.25rem;
  }
}

.mobileLogo {
  display: none;
}

.sidebar.isTransitioning {
  overflow: hidden;

  > nav {
    width: var(--sidebar-open-width);
  }
}

.sidebar > nav {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
}

@media (prefers-reduced-motion) {
  .sidebar {
    transition: none;
  }
}

@include media-breakpoint-down(md) {
  .sidebar {
    align-items: center;
    flex-direction: row;
    height: var(--navigation-mobile-header-height);
    justify-content: space-between;
    padding: 0 1rem;
    width: 100%;
    transition: none;

    > nav {
      flex: 0;
    }
  }

  .navRight {
    display: flex;
  }

  .navLeft {
    display: none;
  }

  .mobileLogo {
    display: block;
    height: 1.25rem;
    width: auto;
  }
}
