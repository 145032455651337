@import '~common/ui_imports';

.footer {
  background: var(--navigation-background-color);
  bottom: 0;
  display: flex;
  height: var(--navigation-mobile-footer-height);
  position: sticky;
  z-index: var(--z-index-ui-navbar);
}

.footer nav {
  display: flex;
}

.footer nav,
.footer nav > * {
  flex: 1;
}

.footerNavigationItem {
  align-items: center;
  border-left: none;
  border-top: var(--navigation-item-border-width-thickness) solid
    var(--navigation-background-color);
  display: flex;
  justify-content: center;

  > img {
    margin: 0;
  }
}

@include media-breakpoint-up(md) {
  .footer {
    display: none;
  }
}
