.accountSettingsPopover {
  border-radius: var(--ux-border-radius);
  padding: 0.875rem 1.375rem;
  background: var(--navigation-background-color);
  gap: 0.75rem;
}

.text {
  margin: 0;
}

.teamLink {
  margin-right: -0.625rem;
  color: var(--navigation-text-color);
  text-decoration: none;

  &:hover {
    color: inherit;
    text-decoration: none;
  }
}

.link {
  color: inherit;
  width: 100%;
  text-align: left;

  &:hover,
  &:active {
    color: inherit;
  }
}
